<template>
  <div>
    <paginate
      :page-count="data.countAllPages"
      :click-handler="changePage"
      v-model="page"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :prev-class="'prev-item'"
      :next-class="'next-item'"
    >
      <span slot="prevContent">Changed previous button</span>
      <span slot="nextContent">Changed next button</span>
      <span slot="breakViewContent" class="w-4">
        <svg width="16" height="4" viewBox="0 0 16 4">
          <circle fill="#999999" cx="2" cy="2" r="2" />
          <circle fill="#999999" cx="8" cy="2" r="2" />
          <circle fill="#999999" cx="14" cy="2" r="2" />
        </svg>
      </span>
    </paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  mounted() {
    this.page = 1;
    // console.log("page from component", this.page);
  },
  props: ["data"],
  components: { Paginate },

  data() {
    return {
      countAllPages: this.data.countAllPages,
      page: this.data.page,
    };
  },
  methods: {
    changePage() {
      console.log(this.page);
      console.log(this.countAllPages);
      this.$emit("changePage", this.page);
    },
  },
};
</script>

<style lang="scss" >
.active {
  background-color: #92a3b7 !important;
  border: 1px solid #92a3b7 !important;
  //   border-radius: 50% !important;
  a {
    color: white !important;
  }
}
.page-item,
.next-item,
.prev-item {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0px;
  border: 1px solid rgb(226, 226, 226);
  a {
    color: #92a3b7;
  }
}

.next-item {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
}

.prev-item {
  border-left: 1px solid rgb(226, 226, 226);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>