<template>
  <div class="flex items-center h-full gap-4">
    <div class="crop-size">
      {{ params.value.crop_size_width }}% / {{ params.value.crop_size_height }}%
    </div>
    <button @click="handleClick" class="button-view text-center">
      Set crop size
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MiniBlankModal from "../../../../components/miniblankModal.vue";
export default {
  conponents: {
    MiniBlankModal,
  },
  methods: {
    handleClick() {
      console.log(this.params.value);
      this.params.showCropSize(this.params.value);
    },
  },

  data() {
    return {
      path: null,
    };
  },
  props: {
    crop_size: Array,
  },
};
</script>

<style lang="scss" scoped>
.crop-size {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.7rem;
  height: 2rem;
  border: 1px solid #6a7e91;
  border-radius: 5px;
  font-weight: 500;
}
.button-view {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #ff9446;
  border-radius: 5px;
  border: none;
  padding: 0rem 1rem;
  height: 2rem;
}
</style>