<template>
  <div class="flex items-center h-full">
    <button @click="handleClick" class="button-view text-center">
      Get Link
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MiniBlankModal from "../../../../components/miniblankModal.vue";
export default {
  conponents: {
    MiniBlankModal,
  },
  methods: {
    handleClick() {
      this.params.showPath(this.params.value);
    },
  },
  computed: {},

  data() {
    return {
      path: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-view {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c78be;
  background-color: #beefff;
  border-radius: 5px;
  border: none;
  padding: 0rem 1rem;
  height: 2rem;
}

</style>
