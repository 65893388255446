<template>
  <div
    class="flex items-center justify-center h-8 w-8 gap-4 plus-btn rounded cursor-pointer font-bold"
    @click="handleClick"
  >
    +
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import MiniBlankModal from "../../../../components/miniblankModal.vue";
export default {
  conponents: {
    MiniBlankModal,
  },
  methods: {
    handleClick() {
      this.params.showAddPage(this.params.data);
    },
  },

  data() {
    return {
      path: null,
    };
  },
  props: {
    crop_size: Array,
  },
};
</script>
  
  <style lang="scss" scoped>
.plus-btn {
  background-color: #4b91fa;
  color: white;
}
</style>