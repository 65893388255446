<template>
  <div class="dropdown">
    <div
      :class="publish + '-dropdown'"
      id="selectDiv"
      @click="closeDropdown"
      :value="publish"
    >
      <div>
        {{ publish }}
      </div>
      <div v-if="publish === 'Publish'">
        <img src="../../../../assets/images/elements/PublishDropdown.svg" />
      </div>
      <div v-if="publish === 'Unpublish'">
        <img src="../../../../assets/images/elements/UnpublishDropdown.svg" />
      </div>
      <div v-if="publish === 'Schedule'">
        <img src="../../../../assets/images/elements/ScheduleDropdown.svg" />
      </div>
    </div>
    <div
      class="dropdown-content"
      :class="{ translate: translate }"
      v-click-outside="closeDropdown"
    >
      <div
        class="option cursor-pointer py-2"
        v-for="option in options"
        :class="option"
        :key="option"
        @click="changeStatus(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>
  

<script>
import ClickOutside from "vue-click-outside";

export default {
  mounted() {
    console.log(this.params.data);
    if (this.params.data.reverseCount < 3 && this.params.data.allCount > 6) {
      this.translate = true;
    }
    console.log("this.translate:", this.translate);
    this.isPublish();
    this.dropdown = `../../../../assets/images/elements/${this.publish}Dropdown.svg`;
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      translate: false,
      options: ["Publish", "Unpublish", "Schedule"],
      status: {
        name: "",
        options: [],
      },
      selectedOption: "",
      allowedOptions: [],
      publish: "",
      dropdown: "",
    };
  },
  computed: {},
  methods: {
    isPublish() {
      if (this.is_publish) {
        this.publish =
          this.is_publish.is_publish === "unpublish"
            ? "Unpublish"
            : this.is_publish.is_publish === "publish"
            ? "Publish"
            : "Schedule";
      } else {
        this.publish =
          this.params.value.is_publish === "unpublish"
            ? "Unpublish"
            : this.params.value.is_publish === "publish"
            ? "Publish"
            : "Schedule";
        console.log("publish: ", this.publish);
      }
    },
    getValue() {
      return this.params.value;
    },
    onStatusClick(selectedOption) {
      this.params.onStatusChange({
        oldStatus: this.status.name,
        newStatus: selectedOption.value,
        borrow_asset_id: this.params.data.borrow_asset_id,
      });
      this.params.stopEditing();
    },
    closeDropdown() {
      this.params.stopEditing();
    },
    changeStatus(option) {
      this.$emit("changeStatus", {
        is_publish: option,
      });
      this.params.showPublish({
        is_publish: option,
        catalog_id: this.params.value.catalog_id,
      });
      this.params.stopEditing();
    },
  },
  created() {
    const { name, options } = this.params.value;
    this.status = {
      name,
      options,
    };
  },
  props: {
    is_publish: { catalog_id: Number, is_publish: Number },
  },
};
</script>
  
  <style lang="scss" scoped>
.status-card {
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  p {
    margin: 0;
  }
}

.dropdown {
  position: absolute;
  z-index: 200000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  margin-top: 40px;
}

.menu-dropdown {
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
}

#selectDiv {
  width: 130px;
  height: 32px;
  display: grid;
  grid-template-columns: 5fr 2fr;
  border-radius: 7px;
  text-align: center;
  div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    img {
      width: 10px;
    }
  }
}
.dropdown-content {
  width: 130px;
  // min-width: 190px;
  border-radius: 7px;
  // padding: 1px 1px;
  border: 1px solid black;

  background-color: white;
  .option {
    text-align: center;
    line-height: normal;
    background: white;
    &:hover {
    }
  }
}

.Publish,
.Unpublish,
.Schedule {
  width: 110px;
  border: 0;

  padding: 0.5rem 1rem;
  border-radius: 7px !important;
  // border-right: 1rem solid transparent;
  cursor: pointer;
  margin: 10px 10px;
}

.Publish,
.Publish-dropdown {
  color: #2cbe3f;
  background-color: #beffc7 !important;
}

.translate {
  transform: translateY(-165px);
}

.Unpublish,
.Unpublish-dropdown {
  background-color: #ffbebe !important;
  color: #be2c2c;
}
.Schedule,
.Schedule-dropdown {
  background-color: #e3ddff !important;
  color: #7b60fc;
}

.Publish:hover {
  color: #2cbe3f;
  background-color: #beffc7;
}

.Unpublish:hover {
  background-color: #ffbebe;
  color: #be2c2c;
}
.Schedule:hover {
  background-color: #e3ddff;
  color: #7b60fc;
}
</style>
  