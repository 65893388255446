<template>
  <div class="h-full w-full" id="ag-grid-demo">
    <ag-grid-vue
      ref="agGridTable"
      style="height: 100%"
      :gridOptions="gridOptions"
      class="ag-theme-material ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      @selection-changed="onSelectionChanged"
      :rowData="rowData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :enableRtl="$vs.rtl"
      @first-data-rendered="onFirstDataRendered"
      :setQuickFilter="searchText"
      @gridReady="onGridReady"
      :overlayLoadingTemplate="overlayLoadingTemplate"
    >
      <!-- 
            :pagination="true"
            :suppressPaginationPanel="true"
            :paginationPageSize="paginationPageSize"
            @rowDataChanged="onFirstDataRendered" -->
    </ag-grid-vue>
  </div>
</template>
  
  <script>
import { AgGridVue } from "ag-grid-vue";
import topButton from "../topTableButton/topTableButton";
import { mapGetters } from "vuex";
import iconDetail from "./table_component/iconDetail";
import iconDelete from "./table_component/iconDelete";
import tabComponent from "./../tabComponent/tabComponent";
import Dropdown from "./table_component/Dropdown";
import Text from "./table_component/String";
import Date from "./table_component/Date";
import Float from "./table_component/Float";
import Number from "./table_component/Number";
import Array from "./table_component/Array";
import Publish from "./table_component/Publish";
import Edit from "./table_component/Edit";
import EditProduct from "./table_component/EditProduct";
// import CreateProduct from "./../../elements/";
import Delete from "./table_component/Delete";
import Detail from "./table_component/Detail.vue";
import Export from "./table_component/Export.vue";
import Datepicker from "vuejs-datepicker";
import PublishCatalog from "./table_component/PublishCatalog.vue";
import PublishCatalogEditor from "./table_component/PublishCatalogEditor.vue";
import moment from "moment";
import iconUp from "./table_component/iconUp.vue";
import iconDown from "./table_component/iconDown.vue";
import FormatTime from "./table_component/FormatTime.vue";
import Setstandard from "./table_component/Setstandard";
import Keyword from "./table_component/Keyword.vue";
import AddPage from "./table_component/AddPage.vue";

import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import themeConfig from "@/../themeConfig.js";

export default {
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';

    this.columnDefs = [
      {
        headerName: "No",
        field: "index",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        minWidth: 150,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
      {
        headerName: "Catalog name",
        field: "catalog_name",
        minWidth: 250,
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
      {
        headerName: "Keyword",
        field: "keyword",
        minWidth: 200,
        cellRendererFramework: "Keyword",
        cellRendererParams: { showSetKeyword: this.showSetKeyword.bind(this) },
      },

      {
        headerName: "Total Page",
        field: "total_page",
        minWidth: 140,
      },
      {
        headerName: "Add Page",
        field: "catalog_detail",
        cellRendererFramework: "AddPage",
        minWidth: 140,
        cellRendererParams: { showAddPage: this.showAddPage.bind(this) },
      },
      {
        headerName: "Set standard",
        field: "crop_size",
        cellRendererFramework: "Setstandard",
        minWidth: 220,

        cellRendererParams: { showCropSize: this.showCropSize.bind(this) },
      },
      {
        headerName: "Detail",
        field: "catalog_detail",
        cellRendererFramework: "Detail",
        minWidth: 140,
      },

      {
        headerName: "Propagation",
        field: "is_publish",
        cellRendererFramework: "PublishCatalog",
        minWidth: 180,
        cellRendererParams: { showPublish: this.showPublish.bind(this) },
        editable: true,
        cellEditorFramework: "PublishCatalogEditor",
        singleClickEdit: true,
        cellEditorParams: {
          showPublish: this.showPublish.bind(this),
        },
        // show this editor in a popup
        isPopup: true,
        // position the popup under the cell
        cellEditorFrameworkPopupPosition: "under",
      },
      {
        headerName: "Start Date",
        field: "start_date",
        minWidth: 200,
      },
      {
        headerName: "End Date",
        field: "end_date",
        minWidth: 200,
      },
      {
        headerName: "Export",
        field: "catalog_export",
        cellRendererFramework: "Export",
        minWidth: 150,
        cellRendererParams: { showPath: this.showPath.bind(this) },
      },
    ];
  },
  components: {
    AgGridVue,
    Datepicker,
    iconDetail,
    iconDelete,
    tabComponent,
    topButton,
    Dropdown,
    Text,
    Date,
    Float,
    Number,
    Publish,
    Array,
    Edit,
    Delete,
    Detail,
    iconUp,
    iconDown,
    FormatTime,
    Export,
    Setstandard,
    PublishCatalogEditor,
    PublishCatalog,
    Keyword,
    AddPage,
  },
  props: {
    searchText: {
      type: String,
    },
    rowData: {
      type: Array,
    },
  },
  data() {
    return {
      bermuda: null,
      searchQuery: "",
      gridOptions: { resizable: true, rowHeight: 48 },
      maxPageNumbers: 7,
      gridApi: "sizeColumnsToFit",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      start_date: null,
      end_date: null,
      masks: {
        input: "YYYY-MM-DD",
      },
      username: null,
      password: null,
      name: null,
      role_choice: ["Admin", "Super Admin"],
      role: null,
      columnFilter: null,
      selectFilter: null,
      overlayLoadingTemplate: null,
      columnDefs: null,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    searchText(val) {
      this.gridApi.setQuickFilter(val);
    },
  },

  computed: {
    ...mapGetters({
      //getTable: "general/table",
      //nowPage: "getNowPage",
      activePage: "general/activePage",
      page: "general/page",
      pointer: "general/pointer",
    }),
    Showaddbutton() {
      if (
        this.page.table.add_button.value === true &&
        this.pointer !== "banner"
      ) {
        console.log("check");
        return true;
      }
    },

    start_date_moment() {
      // console.log("start_date", this.start_date);
      const start_date = moment().format("YYYY-MM-01");
      return start_date;
    },
    end_date_moment() {
      // console.log("his.end_date", this.end_date);
      const end_date = moment().format("YYYY-MM-") + moment().daysInMonth();

      return end_date;
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    UpdateData() {
      console.log(">>>>", this.page.table.data);
      return this.page.table.data;
    },
    HeightDynamic() {
      return this.Height;
    },
  },
  methods: {
    refreshCells() {
      // call at ManageCatalog.vue to refresh table
      this.gridApi.refreshCells({ force: true });
    },
    showPublish(publish) {
      console.log(publish);
      this.$emit("showPublish", publish);
    },
    showSetKeyword(keyword) {
      console.log(keyword);
      this.$emit("showSetKeyword", keyword);
    },
    showPath(path) {
      console.log(path);
      this.$emit("showPath", path);
    },

    showAddPage(data) {
      this.$emit("showAddPage", data);
    },
    showCropSize(size) {
      this.$emit("showCropSize", size);
    },
    onCellClicked(params) {
      console.log("Cell was clicked");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      return selectedData;
    },

    onSelectionChanged() {
      this.$emit(
        "AllowClick",
        this.gridApi.getSelectedNodes().length !== 0 ? true : false
      );
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    changeTabActive(index) {
      this.$store.dispatch("home/changeTabActive", index);
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },
    openModal() {
      this.$bvModal.show("filter_button");
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.bermuda = themeConfig.theme.bermuda;
  },
  updated() {
    this.onSelectionChanged();
  },
};
</script>
  <style lang="scss" >
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}

.search-table .vs-con-input .vs-inputx {
  line-height: 1.5;
  border: none !important;
}

.search-table .vs-con-input .material-icons {
  line-height: 1.5;
}

.add-button {
  border-radius: 10px;
  background-color: #0430ad !important;
  font-family: "Montserrat", "Athiti", sans-serif;
}

.add-banner {
  border-radius: 10px;
  color: #0430ad;
  background-color: #ffffff !important;
  font-family: "Montserrat", "Athiti", sans-serif;
  width: max-content;
}

.tablestyle {
  background: white;
}

.table th {
  color: #ffffff;
  vertical-align: middle;
  border-bottom: 1px solid black !important;
}
.table-bordered th {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid black !important;
}
.table-bordered td {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}
.table-bordered {
  border: 0 !important;
}

// .ag-header-row {
//   :nth-child(1) {
//   }
//   :nth-child(2) {
//   }
//   :nth-child(3) {
//     justify-content: center !important;
//   }
//   :nth-child(4) {
//     justify-content: center !important;
//   }
//   :nth-child(5) {
//     justify-content: center !important;
//   }
// }
// .ag-header-cell-label {
//   justify-content: center !important;
// }

// .ag-row {
//   :nth-child(1) {
//     justify-content: start !important;
//   }
//   :nth-child(2) {
//     justify-content: start !important;
//   }
// }

.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-cell-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixjustify {
  display: flex !important;
  justify-content: end !important;
}

.tablename {
  font-size: 1.125rem;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing {
  box-shadow: none !important;
  background: transparent !important;
}
.ag-root-wrapper {
  border: solid 1px;
  border-color: var(--ag-border-color, #babfc7) !important;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-cell-wrapper .ag-cell-value {
  width: 100% !important;
  // overflow: visible;
  height: 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ag-theme-material .ag-cell {
  line-height: 0px !important;
  height: 48px !important;
  overflow: visible;
}
.ag-grid-table .ag-cell-inline-editing {
  height: 140px !important;
  // top: 40px;
  // width: 130px !important;
  // left: 785px !important;
}

.ag-grid-table .ag-icon-checkbox-checked,
.ag-grid-table .ag-icon-checkbox-indeterminate {
  color: #ff0036 !important;
}
</style>
  