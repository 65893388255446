<template>
  <div class="contentArea h-full overflow-auto w-full" style="color: #6a7e91">
    <div class="bg-white flex flex-col p-6 h-full">
      <div class="flex flex-col lg:flex-row gap-4 justify-between grow-0 my-5">
        <div class="flex gap-5 catalog-ag-grid">
          <button
            class="fill-button fill-button-pri main-create-btn flex justify-center items-center gap-2"
            @click="openCreateCatalogModal"
          >
            <img
              class="pointer w-4 h-4"
              src="@/assets/images/icon/plus.svg"
              alt=""
            />
            Create New Catalog
          </button>
          <button
            :class="{
              'border-button-disable': !allowClick,
              'red-btn': allowClick,
            }"
            class="border-button border-button-red flex justify-center items-center gap-2"
            @click="openDeleteCatalogModal"
            :disabled="!allowClick"
          >
            <img
              class="w-4 h-4"
              :class="{ 'filter-white': allowClick }"
              src="@/assets/images/icon/trash.svg"
              alt=""
            />
            Delete
          </button>
        </div>
        <div>
          <form @submit.prevent="onSearch">
            <input
              class="text-input searchBar"
              v-model="searchText"
              placeholder="search"
              :class="{ 'input-bg': !searchText }"
              @click="refreshTable"
            />
          </form>
        </div>
      </div>
      <CatalogTable
        @AllowClick="isAllowClick"
        ref="catalogTable"
        :rowData="rowData"
        class="h-full grow bg-red-200"
        v-on:showPath="showPath"
        v-on:showCropSize="showCropSize"
        v-on:showPublish="showPublish"
        v-on:showSetKeyword="showSetKeyword"
        v-on:showAddPage="showAddPage"
      />
      <div class="flex items-center justify-center mt-4">
        <pagination
          v-if="renderComponent"
          :data="page_data"
          v-on:changePage="changePage"
        />
        <!-- <paginate
          :page-count="countAllPages"
          v-model="page"
          :click-handler="changePage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          class="gap-4"
          :page-class="'page-item'"
        >
          <span slot="prevContent">Changed previous button</span>
          <span slot="nextContent">Changed next button</span>
          <span slot="breakViewContent">
            <svg width="16" height="4" viewBox="0 0 16 4">
              <circle fill="#999999" cx="2" cy="2" r="2" />
              <circle fill="#999999" cx="8" cy="2" r="2" />
              <circle fill="#999999" cx="14" cy="2" r="2" />
            </svg>
          </span>
        </paginate> -->
      </div>
    </div>
    <!--------------Modal--------------->
    <transition name="fade">
      <SmallBlankModal
        v-if="showCreateCatalogModal"
        @close="closeCreateCatalogModal"
      >
        <template v-slot:body>
          <div class="p-4 container" style="overflow: hidden">
            <p class="font-semibold text-center topic">Upload New Catalog</p>
            <div class="flex items-center justify-around">
              <!-- header -->
              <div>
                <div
                  class="img-box pointer"
                  @click="selectFile"
                  @drop.prevent="onDropFile($event)"
                  @dragenter.prevent="() => {}"
                  @dragover.prevent="() => (dragFile = true)"
                  @dragleave.prevent="() => (dragFile = false)"
                  :class="{ dragFile: dragFile }"
                >
                  <div
                    class="flex items-center justify-center flex-col"
                    style="height: 280px"
                    v-if="!selectedFile"
                  >
                    <img
                      src="@/assets/images/elements/plus.svg"
                      alt=""
                      class="pointer"
                    />
                    <div class="flex justify-center gap-3 font-semibold my-2">
                      Choose a file or drag it here
                    </div>
                  </div>
                  <div class="file-data-container" v-show="selectedFile != ''">
                    <div class="text-bold filename">
                      {{ selectedFile.name }}
                    </div>
                    <div class="flex-col">
                      <div v-if="queue !== 0 && queue !== null">
                        Queue : {{ queue }}
                      </div>
                      <div
                        class="loading-bar"
                        v-show="showProgressBar && queue === 0"
                      >
                        <div
                          class="progress-bar"
                          :style="{ width: progress }"
                        ></div>
                      </div>
                      <div
                        v-show="showProgressBar && queue === 0"
                        class="text-bold"
                      >
                        Loading {{ progress }}
                      </div>
                      <div v-show="showProgressBar">
                        Do not close the window, PDF is loading
                      </div>
                    </div>
                  </div>

                  <input
                    ref="uploadPdf"
                    style="display: none"
                    @change="onFileSelected($event)"
                    type="file"
                    accept="application/pdf"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5 text-center">Maximum image size: 200 MB</div>

            <div class="flex flex-col gap-4 mb-4">
              <div class="flex justify-center items-center w-full flex-col">
                <input
                  class="text-input p-4 w-2/3 mb-4"
                  id="catalog-input"
                  v-model="catalogName"
                  placeholder="Catalog name"
                  type="text"
                  :disabled="showProgressBar"
                  required
                />
                <input
                  class="text-input p-4 w-2/3"
                  id="catalog-input"
                  v-model="keyword"
                  placeholder="Keyword"
                  type="text"
                  :disabled="showProgressBar"
                  required
                />
              </div>
            </div>

            <div class="flex justify-center items-center">
              <div
                class="flex justify-between items-center w-2/3"
                v-if="!showProgressBar"
              >
                <button
                  type="button"
                  @click="closeCreateCatalogModal"
                  class="border-button-red btn cancel-btn w-1/2"
                  v-show="!showProgressBar"
                >
                  Cancel
                </button>
                <button
                  class="border-button border-button-pri btn create-btn w-1/2"
                  @click="uploadFile"
                  v-show="!showProgressBar"
                  :disabled="
                    selectedFile === '' || catalogName === '' || keyword === ''
                  "
                >
                  Create
                </button>
              </div>
              <div
                class="flex justify-center items-center w-2/3"
                v-if="showProgressBar"
              >
                <button
                  v-show="showProgressBar"
                  class="border-button border-button-pri create-btn"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>

      <SmallBlankModal v-if="showAddPageModal" @close="closeAddPageModal">
        <template v-slot:body>
          <div class="p-4 container" style="overflow: hidden">
            <p class="font-semibold text-center topic">
              Add page for "{{ addPageFile.catalog_name }}"
            </p>
            <div class="flex items-center justify-around">
              <!-- header -->
              <div>
                <div
                  class="img-box pointer"
                  @click="selectFile"
                  @drop.prevent="onDropFile($event)"
                  @dragenter.prevent="() => {}"
                  @dragover.prevent="() => (dragFile = true)"
                  @dragleave.prevent="() => (dragFile = false)"
                  :class="{ dragFile: dragFile }"
                >
                  <div
                    class="flex items-center justify-center flex-col"
                    style="height: 280px"
                    v-if="!selectedFile"
                  >
                    <img
                      src="@/assets/images/elements/plus.svg"
                      alt=""
                      class="pointer"
                    />
                    <div class="flex justify-center gap-3 font-semibold my-2">
                      Choose a file or drag it here
                    </div>
                  </div>
                  <div class="file-data-container" v-show="selectedFile != ''">
                    <div class="text-bold filename">
                      {{ selectedFile.name }}
                    </div>
                    <div class="flex-col">
                      <div v-if="queue !== 0 && queue !== null">
                        Queue : {{ queue }}
                      </div>
                      <div
                        class="loading-bar"
                        v-show="showProgressBar && queue === 0"
                      >
                        <div
                          class="progress-bar"
                          :style="{ width: progress }"
                        ></div>
                      </div>
                      <div
                        v-show="showProgressBar && queue === 0"
                        class="text-bold"
                      >
                        Loading {{ progress }}
                      </div>
                      <div v-show="showProgressBar">
                        Do not close the window, PDF is loading
                      </div>
                    </div>
                  </div>

                  <input
                    ref="uploadPdf"
                    style="display: none"
                    @change="onFileSelected($event)"
                    type="file"
                    accept="application/pdf"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5 text-center">Maximum image size: 200 MB</div>

            <div class="flex flex-col gap-4 mb-4">
              <div class="flex justify-center items-center w-full flex-col">
                <div
                  class="text-input p-4 w-2/3 mb-4"
                  id="catalog-input"
                  placeholder="Catalog name"
                  type="text"
                  :disabled="showProgressBar"
                  required
                >
                  {{ addPageFile.catalog_name }}
                </div>
                <div
                  class="text-input p-4 w-2/3"
                  id="catalog-input"
                  placeholder="Keyword"
                  type="text"
                  :disabled="showProgressBar"
                  required
                >
                  {{ addPageFile.keyword.keyword }}
                </div>
              </div>
            </div>

            <div class="flex justify-center items-center">
              <div
                class="flex justify-between items-center w-2/3"
                v-if="!showProgressBar"
              >
                <button
                  type="button"
                  @click="closeAddPageModal"
                  class="border-button-red btn cancel-btn w-1/2"
                  v-show="!showProgressBar"
                >
                  Cancel
                </button>
                <button
                  class="border-button border-button-pri btn create-btn w-1/2"
                  @click="uploadFile"
                  v-show="!showProgressBar"
                  :disabled="selectedFile === ''"
                >
                  Add Page
                </button>
              </div>
              <div
                class="flex justify-center items-center w-2/3"
                v-if="showProgressBar"
              >
                <button
                  v-show="showProgressBar"
                  class="border-button border-button-pri create-btn"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>

      <MiniBlankModal
        v-if="showDeleteCatalogModal"
        @close="closeDeleteCatalogModal"
      >
        <template v-slot:body>
          <div class="flex flex-col gap-4 p-4">
            <p class="font-semibold text-center">
              Are you sure that you want to delete this product?
            </p>

            <div class="flex justify-around">
              <button
                @click="closeDeleteCatalogModal"
                style="background-color: rgb(235, 235, 235)"
                class="border-button border-button-pri"
              >
                Cancel
              </button>
              <button
                @click="deleteCatalog"
                style="background-color: red; color: #ffffff"
                class="border-button border-button-pri"
              >
                Delete
              </button>
            </div>
          </div>
        </template>
      </MiniBlankModal>
      <SmallBlankModal
        v-if="showExportCatalogModal"
        @close="closeExportCatalogModal"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-12 topic">
              Export Catalog's Link
            </div>
            <div class="flex justify-center items-center w-2/3 mb-4">
              <input
                ref="copyData"
                class="text-center text-input w-2/3 export-link-input"
                readonly
                :value="exportLink"
              />
              <button
                type="submit"
                class="border-button border-button-pri btn create-btn copy-btn"
                @click="copyToClipboard"
              >
                <img
                  src="../../assets/images/icon/copy.svg"
                  width="15"
                  height="15"
                />
              </button>
            </div>
            <div>
              <label class="mr-4">Page: </label>
              <input
                type="number"
                v-model="pageInput"
                @input="updateExportLink"
                class="text-center mb-4 text-input w-2/3 page-input"
              />
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeExportCatalogModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
      <SmallBlankModal
        v-if="showCropSizeCatalogModal"
        @close="closeCropSizeCatalogModal"
        class="overflow-visible"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-10 topic">
              Set standard catalog’s crop size
            </div>
            <div class="flex w-full justify-center items-center mb-8">
              <div class="flex w-1/3 justify-center items-center gap-1">
                <label class="mr-4">Width</label>
                <input
                  class="text-center mb-4 text-input w-1/3"
                  type="number"
                  min="0"
                  max="100"
                  v-model="cropWidth"
                />
                <span>%</span>
              </div>
              <div class="flex w-1/3 justify-center items-center gap-1">
                <label class="mr-4">Height</label>
                <input
                  class="text-center mb-4 text-input w-1/3"
                  type="number"
                  min="0"
                  max="100"
                  v-model="cropHeight"
                />
                <span>%</span>
              </div>
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeCropSizeCatalogModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="border-button border-button-pri btn create-btn text-center"
                  @click="setCropSize"
                >
                  Set crop size
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
      <SmallBlankModal
        v-if="showSetScheduleModal"
        @close="closeSetScheduleModal"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-10 topic">
              Scheduling "{{ scheduleDetail.catalog_name }}"
            </div>
            <div class="flex w-full justify-center items-center mb-8 flex-col">
              <div class="flex justify-between items-center mb-4 w-2/5">
                <span>Start date: </span
                ><DatePicker
                  v-model="startDate"
                  :popover="popover"
                  @input="checkStartDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-container" v-on="inputEvents">
                      <input
                        class="date-selector"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <img src="../../assets/images/icon/calendar.svg" />
                    </div>
                  </template>
                </DatePicker>
              </div>
              <div class="flex justify-between items-center w-2/5">
                <span>End date: </span
                ><DatePicker
                  v-model="endDate"
                  :popover="popover"
                  @input="checkEndDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-container" v-on="inputEvents">
                      <input
                        class="date-selector"
                        :value="inputValue"
                        v-on="inputEvents"
                        :min="startDate"
                      />
                      <img src="../../assets/images/icon/calendar.svg" />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeSetScheduleModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="border-button border-button-pri btn create-btn text-center"
                  @click="setSchedule"
                >
                  Schedule now
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
      <SmallBlankModal v-if="showSetKeywordModal" @close="closeSetKeywordModal">
        <template v-slot:body>
          <div class="flex flex-col w-full h-full justify-center items-center">
            <div class="font-semibold text-center mb-10 topic">
              Edit Keyword
            </div>
            <div class="flex w-full justify-center items-center mb-8 flex-col">
              <div class="flex justify-center items-center mb-4 w-3/5">
                <span class="mr-4">Old Keyword: </span
                ><input
                  type="text"
                  disabled
                  class="input-keyword"
                  v-model="oldKeyword"
                />
              </div>
              <div class="flex justify-center items-center w-3/5">
                <span class="mr-3">New Keyword: </span
                ><input
                  type="text"
                  class="input-keyword"
                  v-model="newKeyword"
                />
              </div>
            </div>
            <div class="flex justify-center items-center w-full">
              <div class="flex justify-around items-center w-2/3">
                <button
                  type="button"
                  @click="closeSetKeywordModal"
                  class="border-button-red btn cancel-btn close-btn2"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="border-button border-button-pri btn create-btn text-center"
                  @click="setNewKeyword"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </template>
      </SmallBlankModal>
    </transition>
    <!--------------Modal--------------->
  </div>
</template>

<script>
import AgGrid from "../elements/ag-grid-table/AgGridTable.vue";
import CatalogTable from "../elements/ag-grid-table/CatalogTable.vue";
import BlankModal from "../../components/blankModal.vue";
import MiniBlankModal from "../../components/miniblankModal.vue";
import SmallBlankModal from "../../components/smallblankModal.vue";
import axios from "../../axios";
import { DatePicker } from "v-calendar";
import { catalogBaseurl, catalogLiffUrl, stage } from "../../util/baseUrl";
import moment, { defaultFormat } from "moment";
import { ref } from "vue";
import Clipboard from "clipboard";
import io from "socket.io-client";
import Paginate from "vuejs-paginate";
import VueAdsPagination from "vue-ads-pagination";
import Pagination from "../../components/pagination.vue";

window.onbeforeunload = function () {
  if (this.socket.connected) {
    this.socket.disconnect();
  } else {
    console.log("disconnected");
  }
  return "Are you sure you want to close the window?";
};
export default {
  data() {
    return {
      showSetKeywordModal: false,
      oldKeyword: "",
      newKeyword: "",
      keyword: "",
      renderComponent: true,
      dragFile: false,
      page_data: {},
      tableSize: 12,
      page: 1,
      showProgressBar: false,
      currentModalId: null,
      lineliffUrl: "",
      allowClick: false,
      catalogName: "",
      basePath: "",
      exportLink: "",
      showCreateCatalogModal: false,
      showDeleteCatalogModal: false,
      showExportCatalogModal: false,
      showCropSizeCatalogModal: false,
      showSetScheduleModal: false,
      showAddPageModal: false,
      scheduleDetail: {},
      pageInput: "",
      cropWidth: 0,
      cropHeight: 0,
      startDate: new Date(),
      endDate: new Date(),
      popover: { visibility: "click" },
      rowData: [],
      selectedFile: "",
      searchText: "",
      progress: "0%",
      socket: null,
      countAllPages: 0,
      uuid: null,
      process: "NOT_START",
      queue: null,
      lastPage: 0,
      firstPage: 1,
      addPageFile: {},
    };
  },

  components: {
    AgGrid,
    BlankModal,
    MiniBlankModal,
    SmallBlankModal,
    CatalogTable,
    DatePicker,
    Paginate,
    VueAdsPagination,
    Pagination,
  },
  methods: {
    async onSearch() {
      // this.$refs.catalogTable.refreshCells();
      console.log("onSearch called!");
      this.renderComponent = false;

      // Then, wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add MyComponent back in
      this.renderComponent = true;
      this.changePage(1);
      this.page = 1;
    },
    onDropFile(e) {
      console.log(e.dataTransfer.files[0]);
      if (e.dataTransfer.files[0].type !== "application/pdf") {
        alert("Please choose PDF file!");
        return;
      }
      console.log(
        "size: ",
        (e.dataTransfer.files[0].size / 1024 / 1024).toFixed(4),
        "MB"
      );
      if ((e.dataTransfer.files[0] / 1024 / 1024).toFixed(4) > 200) {
        alert("File is too Big!");
        return;
      }
      this.selectedFile = e.dataTransfer.files[0];
      this.dragFile = false;
      console.log("Filename: ", this.selectedFile.name);
    },
    async changePage(page) {
      this.page = page;
      console.log("page: ", this.page);
      await this.getAllCatalogs();
    },
    onFileSelected(event) {
      console.log(
        "size: ",
        (event.target.files[0].size / 1024 / 1024).toFixed(4),
        "MB"
      );
      if ((event.target.files[0].size / 1024 / 1024).toFixed(4) > 200) {
        alert("File is too Big!");
        return;
      }
      this.selectedFile = event.target.files[0];
      console.log("Filename: ", this.selectedFile.name);
    },
    async uploadFile() {
      this.process = "RUN";
      this.showProgressBar = true;
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      // console.log("upload: ", formData);
      console.log("url: ", catalogBaseurl());
      let path = "/dev/socket.io";
      console.log("stage: ", stage());
      if (stage() === "production") {
        path = "/prod/socket.io";
      }
      try {
        this.socket = io(`https://api-op.central-robinson-line.com`, {
          path: path,
          extraHeaders: { "ngrok-skip-browser-warning": "asd" },
        });
        console.log("socket: ", this.socket);

        this.socket.on("clientUuid", async (id) => {
          // console.log("uuid: ", id);
          this.uuid = id;
          console.log(this.uuid);
          formData.append("clientUuid", this.uuid);
          // console.log("formData: ", formData);
          // console.log("socketId: ", this.socket.id);
          if (this.showAddPageModal) {
            await axios.put(
              `${catalogBaseurl()}/api/catalog/addPage/${
                this.addPageFile.catalog_id
              }`,
              formData,
              {
                headers: { "ngrok-skip-browser-warning": "asd" },
              }
            );
          } else {
            formData.append("catalogName", this.catalogName);
            formData.append("keyword", this.keyword);
            await axios.post(
              `${catalogBaseurl()}/api/catalog/createCatalog`,
              formData,
              {
                headers: { "ngrok-skip-browser-warning": "asd" },
              }
            );
          }
        });

        // if (this.socket.connected) {
        //   console.log("connected");
        // } else {
        //   console.log("disconnected");
        // }
        // console.log("socket: ", this.socket);
        // console.log(`${catalogBaseurl()}`);
        this.socket.on("message", (data) => {
          console.log("data from socket: ", data);
        });
        this.socket.on("progress", (data) => {
          this.queue = 0;
          this.progress = data.toString() + "%";
        });

        this.socket.on("queue", (data) => {
          console.log("queue data: ", data);
          this.queue = data;
        });

        this.socket.on("disconnect", async () => {
          // if (this.socket.connected) {
          //   console.log("connected");
          // } else {
          //   console.log("disconnected");
          // }
          // console.log("socketId: ", this.socket.id);

          await this.getAllCatalogs();
          setTimeout(() => {
            this.showProgressBar = false;
            this.selectedFile = "";
            this.catalogName = "";
            this.progress = "0%";
            this.process = "NOT_START";
            this.queue = null;
            this.keyword = "";
            this.closeCreateCatalogModal();
            this.showAddPageModal = false;
          }, 2000);
        });

        // this.socket.emit("connection", () => {
        //   console.log("connected!");
        // });
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        console.log(err);
        if (err.response.data.status != 504 || err != 504) {
          if (this.socket.connected) {
            this.socket.disconnect();
          } else {
            console.log("disconnected");
          }
        }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }

      // this.socket.emit("file-upload", formData);
    },
    selectFile() {
      if (this.process !== "RUN") {
        this.$refs.uploadPdf.click();
      }
      return;
    },
    checkStartDate() {
      console.log(this.startDate);
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
    },
    checkEndDate() {
      if (this.endDate < this.startDate) {
        this.startDate = this.endDate;
      }
    },
    async getAllCatalogs() {
      try {
        let res = await axios
          .get(
            `${catalogBaseurl()}/api/catalog/catalogPaging?page=${
              this.page
            }&size=${this.tableSize}&key=${this.searchText}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => res.data.data);
        console.log(res);
        this.countAllPages = Math.ceil(res.count / this.tableSize);
        this.page_data = {
          countAllPages: this.countAllPages,
          page: this.page,
        };
        const length = res.rows.length;
        console.log("length", length);
        console.log(this.page_data);
        res = res.rows.map((item, i) => {
          let data = {
            ...item,
            allCount: length,
            reverseCount: length - i - 1,
            crop_size: {
              catalog_id: item.catalog_id,
              crop_size_height: item.crop_size_height,
              crop_size_width: item.crop_size_width,
            },
            catalog_export: {
              catalog_id: item.catalog_id,
              lastPage: item.page_img.length,
            },
            is_publish: {
              catalog_id: item.catalog_id,
              is_publish: item.status,
            },

            total_page: item.page_img.length,
            keyword: {
              catalog_id: item.catalog_id,
              keyword: item.keyword,
            },
            start_date:
              item.start_date === null
                ? "-"
                : moment(item.start_date).format("DD/MM/YYYY"),
            end_date:
              item.end_date === null
                ? "-"
                : moment(item.end_date).format("DD/MM/YYYY"),
            catalog_detail: {
              catalog_id: item.catalog_id,
            },
          };

          delete data.crop_size_height;
          delete data.crop_size_width;
          return data;
        });
        this.rowData = res;
        console.log("res: ", res);
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
      // this.rowData = await axios.get(
      //   `${catalogBaseurl()}/api/catalog/getAllCatalog`
      // );
      console.log("getAllCatalog: ", this.rowData);
    },
    isAllowClick(val) {
      // console.log('isAllowClick',val);
      this.allowClick = val;
      console.log(this.allowClick);
    },
    createCatalog() {
      const index = this.rowData.length + 1;
      const newData = {
        index: index,
        catalog_id: index,
        catalog_name: this.catalogName,
        catalog_duration: moment(new Date()).format("DD/MM/YYYY"),
        is_publish: {
          catalog_id: index,
          is_publish: 0,
        },
        crop_size: {
          catalog_id: index,
          height: 15,
          width: 15,
        },
        catalog_export: `www.example${index}.com`,
        catalog_detail: {
          catalog_id: index,
        },
      };
      this.rowData.push(newData);
      console.log(this.rowData);
      this.catalogName = "";
      this.keyword = "";
      console.log("created");
      this.showCreateCatalogModal = false;
    },
    async deleteCatalog() {
      let rows = await this.$refs.catalogTable.getSelectedRowData();

      console.log("rows:", rows);
      let catalog_id_arr = rows.map((each) => {
        return each.catalog_id;
      });
      const data = {
        catalog_id_arr: catalog_id_arr,
      };
      console.log("req data: ", data);
      await axios.delete(`${catalogBaseurl()}/api/catalog/deleteCatalog`, {
        data: {
          catalog_id_arr: catalog_id_arr,
        },
        headers: {
          "ngrok-skip-browser-warning": "asd",
        },
      });
      this.getAllCatalogs();
      this.showDeleteCatalogModal = false;
    },
    showAddPage(data) {
      console.log("showAddPage", data);
      this.showAddPageModal = true;
      this.addPageFile = data;
    },
    showSetKeyword(data) {
      console.log("data: ", data);
      this.currentModalId = data.catalog_id;
      this.oldKeyword = data.keyword;
      this.newKeyword = "";
      this.showSetKeywordModal = true;
    },
    async setNewKeyword() {
      let data = {
        catalog_id: this.currentModalId,
        keyword: this.newKeyword,
      };
      console.log("data: ", data);
      console.log("url: ", `${catalogBaseurl()}/api/catalog/updateCatalog`);
      try {
        await axios
          .put(`${catalogBaseurl()}/api/catalog/updateCatalog`, data, {
            headers: {
              "ngrok-skip-browser-warning": "asd",
            },
          })
          .then((res) => console.log("updated: ", res));
        this.newKeyword = "";
        this.showSetKeywordModal = false;
        await this.getAllCatalogs();
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
    },
    async setCropSize() {
      let data = {
        catalog_id: this.currentModalId,
        crop_size_height: this.cropHeight.toString(),
        crop_size_width: this.cropWidth.toString(),
      };
      console.log("data: ", data);
      console.log("url: ", `${catalogBaseurl()}/api/catalog/updateCatalog`);
      try {
        await axios
          .put(
            `${catalogBaseurl()}/api/catalog/updateCatalog`,
            {
              catalog_id: this.currentModalId,
              crop_size_height: this.cropHeight,
              crop_size_width: this.cropWidth,
            },
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => console.log("updated: ", res));
        this.showCropSizeCatalogModal = false;
        await this.getAllCatalogs();
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
    },
    async setSchedule() {
      try {
        console.log("data to send: ", {
          catalog_id: this.currentModalId,
          status: "schedule",
          start_date: moment(this.startDate).startOf("D").toISOString(),
          end_date: moment(this.endDate).endOf("D").toISOString(),
        });
        await axios
          .put(
            `${catalogBaseurl()}/api/catalog/updateCatalog`,
            {
              catalog_id: this.currentModalId,
              status: "schedule",
              start_date: moment(this.startDate).startOf("D").toISOString(),
              end_date: moment(this.endDate).endOf("D").toISOString(),
            },
            {
              headers: {
                "ngrok-skip-browser-warning": "asd",
              },
            }
          )
          .then((res) => console.log("updated: ", res));
        this.showSetScheduleModal = false;
        this.startDate = new Date().toISOString();
        this.endDate = new Date().toISOString();
        await this.getAllCatalogs();
      } catch (err) {
        // if (err.response.data.message) {
        //   alert(err.response.data.message);
        // } else {
        //   alert(err);
        // }
        this.$vs.notify({
          title: "error message",
          text: `${
            err.response.data.message ? err.response.data.message : err
          }`,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }
    },
    copyToClipboard() {
      const copyText = this.$refs.copyData;
      const clipboard = new Clipboard(copyText);

      clipboard.on("success", () => {
        console.log("Copied to clipboard");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        console.log("Failed to copy to clipboard");
        clipboard.destroy();
      });

      // Trigger the copy action
      copyText.select();
      document.execCommand("copy");
    },

    showPath(path) {
      console.log("asdadasdasdasdasd");
      this.lastPage = path.lastPage;
      this.basePath = this.lineliffUrl + `?catalog_id=${path.catalog_id}`;
      this.exportLink = this.basePath;
      this.openExportCatalogModal();
    },
    updateExportLink() {
      if (this.pageInput !== "") {
        if (this.pageInput < this.firstPage) {
          this.pageInput = this.firstPage;
        }
        if (this.pageInput > this.lastPage) {
          this.pageInput = this.lastPage;
        }
        this.exportLink = this.basePath + `&page=${this.pageInput}`;
      } else {
        this.exportLink = this.basePath;
      }
      console.log("basePath: ", this.basePath);
      console.log("ExportLink: ", this.exportLink);
    },
    showCropSize(data) {
      this.currentModalId = data.catalog_id;
      this.cropWidth = data.crop_size_width;
      this.cropHeight = data.crop_size_height;
      this.openCropSizeCatalogModal();
    },
    async showPublish(publish) {
      console.log("manage catalog: ", publish);
      this.currentModalId = publish.catalog_id;
      this.scheduleDetail = publish;

      const name = this.rowData.filter((data) => {
        if (data.catalog_id === this.scheduleDetail.catalog_id) {
          return data;
        } else {
          return;
        }
      });
      this.scheduleDetail.catalog_name = name[0].catalog_name;
      console.log("scheduleDetail: ", this.scheduleDetail);
      console.log("publish.is_publish: ", publish.is_publish);
      if (publish.is_publish === "Schedule") {
        // console.log(name);
        // if (name[0].start_date !== "-") {
        //   const startdate = name[0].start_date.split("/");
        //   this.startDate =
        //     startdate[1] + "/" + startdate[0] + "/" + startdate[2];
        //   const enddate = name[0].end_date.split("/");
        //   this.endDate = enddate[1] + "/" + enddate[0] + "/" + enddate[2];
        // } else {
        this.startDate = new Date();
        this.endDate = new Date();
        // }

        console.log(this.endDate);
        this.openSetScheduleModal();
      } else {
        try {
          await axios
            .put(
              `${catalogBaseurl()}/api/catalog/updateCatalog`,
              {
                catalog_id: this.currentModalId,
                status: publish.is_publish.toLowerCase(),
                start_date: null,
                end_date: null,
              },
              {
                headers: {
                  "ngrok-skip-browser-warning": "asd",
                },
              }
            )
            .then((res) => console.log("updated: ", res));
          this.getAllCatalogs();
        } catch (err) {
          // if (err.response.data.message) {
          //   alert(err.response.data.message);
          // } else {
          //   alert(err);
          // }
          this.$vs.notify({
            title: "error message",
            text: `${
              err.response.data.message ? err.response.data.message : err
            }`,
            color: "danger",
            icon: "error",
            position: "top-right",
          });
        }
        console.log(index);
      }
    },
    refreshTable() {
      this.$refs.catalogTable.refreshCells();
    },
    openSetScheduleModal() {
      this.showSetScheduleModal = true;
    },
    closeSetScheduleModal() {
      this.$refs.catalogTable.refreshCells();
      this.showSetScheduleModal = false;
    },
    openCropSizeCatalogModal() {
      this.showCropSizeCatalogModal = true;
    },
    closeCropSizeCatalogModal() {
      this.showCropSizeCatalogModal = false;
    },
    openCreateCatalogModal() {
      this.showCreateCatalogModal = true;
    },
    closeCreateCatalogModal() {
      if (this.process !== "RUN") {
        this.showCreateCatalogModal = false;
      }
      return;
    },
    closeAddPageModal() {
      if (this.process !== "RUN") {
        this.showAddPageModal = false;
      }
      return;
    },
    openDeleteCatalogModal() {
      this.showDeleteCatalogModal = true;
    },
    closeDeleteCatalogModal() {
      this.showDeleteCatalogModal = false;
    },
    openExportCatalogModal() {
      this.showExportCatalogModal = true;
      console.log(this.showExportCatalogModal);
    },
    closeExportCatalogModal() {
      this.pageInput = "";
      this.showExportCatalogModal = false;
    },
    openSetKeywordModal() {
      this.showSetKeywordModal = true;
    },
    closeSetKeywordModal() {
      this.$refs.catalogTable.refreshCells();
      this.showSetKeywordModal = false;
    },
  },
  async mounted() {
    await this.getAllCatalogs();
    await this.$store.dispatch("general/push_activePage", {
      name: "Catalog management",
    });
    this.lineliffUrl = catalogLiffUrl();
  },
};
</script>

<style  lang="scss" scoped>
.topic {
  font-size: 20px;
  color: #000;
}
.main-create-btn {
  background-color: #4b91fa !important;
}
.img-box {
  border: 1px solid #6a7e91;
  border-radius: 10px;
  width: 30rem;
  height: 20rem;
  background-color: #ffffff;
}
// .btn {
//   padding: 5px 50px;
// }

.cancel-btn {
  background: rgba(29, 32, 35, 0.25);
  max-width: 150px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  border-radius: 0.25rem !important;
  color: #000 !important;
}

.close-btn2 {
  background-color: #eeeeee;
}

.create-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #92a3b7;
  color: #ffffff !important;
  max-width: 150px;
  width: 45%;
  border: 0 !important;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem !important;
}
.copy-btn {
  padding: 0px 20px !important;
  width: 70px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 32px !important;
}

.searchBar {
  background-color: white;
  color: #6a7e91;
  border: 1px solid #6a7e91;
}

.export-link-input {
  background-color: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 32px !important;
  border: 1px solid #92a3b7;
  font-weight: 600;
  width: 350px !important;
  overflow: scroll;
  direction: rtl; /* Add this line to set right-to-left direction */
}

.page-input {
  background-color: #92a3b7;
  color: #ffffff;
  border: 0;
  width: 95px !important;
}

.input-bg {
  background: url("../../assets/images/icon/search.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
}
.filter-white {
  filter: brightness(100);
}

.red-btn {
  background-color: #ff0036;
  border: 1px solid #ff0036;
  color: #ffffff;
}

.red-btn:hover {
  background-color: #d9002f !important;
  border: 1px solid #d9002f !important;
}

.main-create-btn:hover {
  background-color: #3e7ede !important;
  transition-duration: 300ms;
}
.date-container {
  background-color: #c2d7e5;
  color: #6a7e91;
  padding: 2px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .date-selector {
    background-color: #c2d7e5;
    color: #6a7e91;
    border: 0;
    width: 85px;
  }
  .pagination {
    display: flex;
    background-color: red !important;
  }
}
.loading-bar {
  width: 350px !important;
  height: 20px !important;
  border-radius: 80px;
  border: 1px solid #bbc6d3;
  overflow: hidden;
}

.progress-bar {
  height: 20px !important;
  background-color: #bbc6d3;
  border-radius: 0px !important;
  justify-content: start !important;
}

.text-bold {
  font-weight: 700;
}
.file-data-container {
  height: 280px;
  display: grid;
  grid-template-rows: 3fr 3fr;
  div {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .filename {
    justify-content: center;
  }
}

.dragFile {
  // box-shadow: 10px 10px #bbc6d3 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.input-keyword {
  border: 0;
  background-color: #e4edf3;
  padding: 7px 15px;
  border-radius: 5px;
}
</style>