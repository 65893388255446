<template>
  <div class="flex justify-center items-center gap-2 container">
    <div class="text-box">
      <div class="text">
        {{ params.value.keyword }}
      </div>
    </div>

    <img
      src="@/assets/images/icon/edit_keyword.svg"
      width="25"
      class="absolute pointer icon"
      @click="handleClick"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    const x = this.$route.query;
    console.log("keyword: ", this.params.value.keyword);
  },

  data() {
    return {};
  },
  methods: {
    handleClick() {
      console.log("click");
      this.params.showSetKeyword(this.params.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 40px;
}
.icon {
  right: 0;
}
.text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100px !important;
  height: 40px;
  // overflow: hidden;
}
.text {
  // display: flex;
  //   justify-content: center;
  //   align-items: center;
  text-align: center;
  width: 100px !important;
  height: 16px !important;
  line-height: normal !important;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  // margin-top: 20 !important;
}
</style>